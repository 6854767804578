import { get_selected_db_id } from './hash';
import { ligo_search_report } from '../ligo/search_report_functions';
import { populate_genome_search_form } from './ui';

export function is_current_db_report() {
  return get_selected_db_id().endsWith('R');
}

export function is_current_db_genome() {
  return get_selected_db_id().endsWith('G');
}

export function is_current_db_manufacturing() {
  return get_selected_db_id().endsWith('M');
}

export function search_document_options() {
  if (is_current_db_report()) {
    return globalThis.report_search_doc.options;
  }
  if (is_current_db_genome()) {
    return globalThis.search_genome_object.options;
  }
  if (is_current_db_manufacturing()) {
    return globalThis.search_manufacturing_object.options;
  }
}

export function get_search_type() {
  if (is_current_db_report()) {
    return 'SearchReport';
  }
  if (is_current_db_genome()) {
    return 'SearchGenome';
  }
  if (is_current_db_manufacturing()) {
    return 'SearchManufacturing';
  }
}

export function get_load_callback() {
  if (is_current_db_report()) {
    return ligo_search_report;
  }
  if (is_current_db_genome()) {
    return populate_genome_search_form;
  }
  if (is_current_db_manufacturing()) {
    return populate_manufacturing_search_form;
  }
}
