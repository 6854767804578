import { get_values_from, format_value } from './utils';

function get_html_element(element) {
  element = typeof element === 'string' ? document.getElementById(element) : element;
  return element ? element : false;
}

function hide_element(element) {
  const el = get_html_element(element);
  if (el) {
    el.classList.add('hidden');
  }
}

function show_element(element) {
  const el = get_html_element(element);
  if (el) {
    el.classList.remove('hidden');
  }
}

function empty_element(element_or_element_id) {
  const is_id = typeof element_or_element_id === 'string';
  if (is_id) {
    element_or_element_id = document.getElementById(element_or_element_id);
  }
  element_or_element_id?.replaceChildren();
  return true;
}

const current_spinner_status = { enabled: false, delay: 100 };
function spinner_on_with_delay(spinner_element_id = 'document_spinner') {
  const spinner = document.getElementById(spinner_element_id);
  current_spinner_status.enabled = true;
  if (spinner) {
    setTimeout(() => {
      if (current_spinner_status.enabled) {
        spinner.classList.add('opacity-100');
        spinner.classList.remove('opacity-0');
      }
    }, current_spinner_status.delay);
  }
}

function spinner_off(spinner_element_id = 'document_spinner') {
  const spinner = document.getElementById(spinner_element_id);
  current_spinner_status.enabled = false;
  if (spinner) {
    spinner.classList.add('opacity-0');
    spinner.classList.remove('opacity-100');
  }
}

async function hide_element_with_opacity_transition(
  element_id,
  start_opacity = 'opacity-100',
  end_opacity = 'opacity-0',
  spinner_element_id = 'document_spinner'
) {
  const element = document.getElementById(element_id);
  if (element) {
    element.classList.add(end_opacity);
    element.classList.remove(start_opacity);

    spinner_on_with_delay(spinner_element_id);
  }
}
async function show_element_with_opacity_transition(
  element_id,
  start_opacity = 'opacity-100',
  end_opacity = 'opacity-0',
  spinner_element_id = 'document_spinner'
) {
  const element = document.getElementById(element_id);
  if (element) {
    element.classList.add(start_opacity);
    element.classList.remove(end_opacity);
    spinner_off(spinner_element_id);
  }
}

function set_filled_button_classes(el) {
  el.classList.add(...get_filled_button_classes());
}

function get_filled_button_classes() {
  return ['rounded-sm', 'p-1', 'border-transparent', 'bg-elsred', 'text-white', 'hover:bg-elsred-light', 'disabled:bg-gray-500'];
}

function set_outline_button_classes(el) {
  el.classList.add(...get_outline_button_classes());
}

function get_outline_button_classes() {
  return ['rounded-sm', 'p-1', 'border', 'border-elsred', 'bg-white', 'text-elsred', 'hover:bg-gray-200', 'hover:text-elsred-dark'];
}

function set_text_input_classes(el) {
  el.classList.add(...get_text_input_classes());
}

function get_text_input_classes() {
  return ['rounded-sm', 'border', 'border-gray-500', 'focus:!border-elsred', 'focus:!ring-elsred', 'py-0'];
}

function set_animate_pulse_classes(el) {
  el.classList.add(...get_animate_pulse_classes());
}

function get_animate_pulse_classes() {
  return ['animate-pulse', 'animate-twice', 'animate-duration-[500ms]'];
}

function get_string_document_menu_icons_classes() {
  return 'fa-solid fa-lg cursor-pointer mr-2';
}

function get_document_menu_icons_classes() {
  return ['fa-solid', 'fa-lg', 'cursor-pointer', 'mr-2'];
}

function get_radio_buttons_classes() {
  return ['rounded-md', 'border-gray-300', 'checked:!bg-elsred', 'focus:!border-elsred', 'hover:!ring-elsred', 'focus:ring-elsred'];
}

function replace_value(prefix, name, value) {
  const element = document.getElementById(prefix + name);
  if (typeof element != 'undefined' && element != null) {
    value = format_value(value);
    if (name == 'smpNames') {
      value = value.split('#').join('</li><li>');
      value = '<li>' + value.substring(-4);
    }
    if (value == '') {
      hide_element(element);
    } else if (name !== 'instrument') {
      element.innerHTML = value;
    }
    return true;
  } else {
    return false;
  }
}

function replace_dict(prefix, dict) {
  const properties = globalThis.object_properties(dict);
  for (let i = 0, size = properties.length; i < size; i++) {
    replace_value(prefix, properties[i]['property'], properties[i]['value']);
  }
}

function hide_missing_keys(prefix, dict) {
  let keys = document.querySelector('[id^="report_"]');
  for (let i = 0; i < keys.length; i++) {
    const elem = keys[i];
    const eid = elem.id.split('_')[1];
    if (dict[eid] == null) {
      hide_element(elem.parentElement);
    }
  }
}

function element_value(element_id, optional = undefined) {
  const el = document.getElementById(element_id);
  if (el) {
    return el.value;
  }
  return optional;
}

function create_menu_anchors(items, menu) {
  for (const [item_id, text_and_event] of Object.entries(items)) {
    const template = `
    <a id="${item_id}" role="menuitem" tabindex="-1"
      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:!text-elsred active:bg-gray-300"
      x-text='_("${text_and_event['text']}")'
    >
    </a>
    `;
    menu.insertAdjacentHTML('beforeend', template);
    const event = text_and_event['event'];
    if (event) {
      document.getElementById(item_id).addEventListener(event['type'], event['func']);
    }
  }
}

function show_admin_menu() {
  show_element('admin-li');
}

function destroy_homepage() {
  remove_element('homepage');
}

function create_report_home_page() {
  if (document.getElementById('search_container')) {
    destroy_homepage();
  }
  empty_element('content');

  const report_template = require('../view/document/homepage.handlebars');
  const report_html = report_template({ report_homepage: true });
  document.getElementById('content').insertAdjacentHTML('beforeend', report_html);
  remove_element('expenses_div');
}

export function populate_genome_search_form(style, search_doc = {}) {
  const dict = get_values_from(style, search_doc);
  dict['_id'] = style['_id'];
  dict['mtype'] = 'SearchGenome';
  globalThis.search_genome_object = new material_classes.SearchGenome(dict, style);
  globalThis.search_genome_object.view(document.getElementById('search_container'));
}

export function populate_manufacturing_search_form(style, search_doc = {}) {
  const dict = get_values_from(style, search_doc);
  dict['_id'] = style['_id'];
  dict['mtype'] = 'SearchManufacturing';
  globalThis.search_manufacturing_object = new manufaturing_classes.SearchManufacturing(dict, style);
  globalThis.search_manufacturing_object.view(document.getElementById('search_container'));
}

function insert_genome_homepage(container) {
  if (document.getElementById('search_container')) {
    destroy_homepage();
  }

  const report_template = require('../view/document/homepage.handlebars');
  const report_html = report_template({});
  container.insertAdjacentHTML('beforeend', report_html);
}

function create_genome_home_page(style, search_doc = {}) {
  let container = document.getElementById('content');

  insert_genome_homepage(container);

  populate_genome_search_form(style, search_doc);

  return style;
}

function create_manufacturing_home_page(style, search_doc = {}) {
  let container = document.getElementById('content');

  insert_manufacturing_homepage(container);

  populate_manufacturing_search_form(style, search_doc);

  return style;
}

function insert_manufacturing_homepage(container) {
  if (document.getElementById('search_container')) {
    destroy_homepage();
  }

  const homepage_template = require('../view/document/homepage.handlebars');
  const homepage_html = homepage_template({});
  container.insertAdjacentHTML('beforeend', homepage_html);
}

function remove_element(id) {
  document.getElementById(id)?.remove();
}

function set_input_caret_to_end(element) {
  const caretPosition = element.selectionStart + element.value.length;
  element.setSelectionRange(caretPosition, caretPosition);
}

function show_loader() {
  document.querySelector('#loader').classList.remove('unload');
  document.body.style.overflow = 'hidden';
}

function copy_link_to_clipboard(link_area, modal_id) {
  navigator.clipboard?.writeText(document.getElementById(link_area).innerHTML);
  document.getElementById(modal_id).remove();
  show_ephemeral_toast(_('Copied. "Ctrl + V" to paste it'), 'Info');
}

function dateToISO(date) {
  // toLocaleDateString("en-CA") is unreliable across browsers
  // return date.toISOString().split('T')[0] is broken with time saver;
  return date.toLocaleDateString('en-CA');
}

function get_key_by_value(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

globalThis.get_filled_button_classes = get_filled_button_classes;
globalThis.get_outline_button_classes = get_outline_button_classes;
globalThis.get_document_menu_icons_classes = get_document_menu_icons_classes;
globalThis.get_string_document_menu_icons_classes = get_string_document_menu_icons_classes;
globalThis.get_text_input_classes = get_text_input_classes;
globalThis.get_radio_buttons_classes = get_radio_buttons_classes;
globalThis.remove_element = remove_element;
globalThis.empty_element = empty_element;

export {
  hide_element,
  show_element,
  empty_element,
  set_filled_button_classes,
  set_outline_button_classes,
  set_text_input_classes,
  set_animate_pulse_classes,
  hide_missing_keys,
  element_value,
  create_menu_anchors,
  show_admin_menu,
  create_report_home_page,
  remove_element,
  set_input_caret_to_end,
  show_loader,
  copy_link_to_clipboard,
  dateToISO,
  replace_dict,
  create_genome_home_page,
  hide_element_with_opacity_transition,
  show_element_with_opacity_transition,
  create_manufacturing_home_page,
};
